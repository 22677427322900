// Check permissions for both camera and microphone
export const permissionQuery = async (permission) => {
  try {
    const result = await navigator.permissions.query({ name: permission });
    console.log(result);
    return result.state;
  } catch (error) {
    return null;
  }
};

// Checking all the permissions
export const checkMediaPermissions = async () => {
  try {
    // Determine the states of permissions

    const videoState = await permissionQuery("camera");
    const audioState = await permissionQuery("microphone");

    console.log(videoState, audioState);

    if (videoState === "prompt" && audioState === "prompt") return null;

    const video = videoState === "granted" ? true : false;
    const audio = audioState === "granted" ? true : false;
    return { audio, video };
  } catch (error) {
    console.error("Error checking media permissions:", error);
    return null; // Handle errors gracefully by returning null
  }
};

// Request microphone permission
export const requestMicrophonePermission = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return { granted: true, message: "Microphone permission granted" };
  } catch (err) {
    return { granted: false, message: "Microphone permission denied" };
  }
};

// Request camera permission
export const requestCameraPermission = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true });
    return { granted: true, message: "Camera permission granted" };
  } catch (err) {
    return { granted: false, message: "Camera permission denied" };
  }
};

// Request both camera and audio permission
export const requestBothPermission = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    return {
      granted: true,
      message: "Both camera and microphone permissions granted",
    };
  } catch (err) {
    return {
      granted: false,
      message: "Camera and/or microphone permission denied",
    };
  }
};
