import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
} from "@mui/material";

import "../App.css";
import {
  SectionHeaderText,
  column,
  infoText,
  marginLeft,
  marginTop,
  marginVertical,
} from "../assets/css/sxStyles";
import { Result } from "antd";
import ReactPlayer from "react-player";
import { getBrowserInfo } from "../common/util";

const PermissionError = ({ data }) => {
  const [browser, setBrowser] = useState("");

  useEffect(() => {
    const detectedBrowser = getBrowserInfo;
    setBrowser(detectedBrowser);
  }, []);

  const videoMap = {
    Chrome: "/videos/permission-chrome.mp4",
    Chrome_Mobile: "/videos/permission-chrome.mp4",
    Safari: "/videos/permission-safari.mp4",
    Safari_Mobile: "/videos/permission-mobile-safari.mp4",
    Firefox: "/videos/permission-firefox.mp4",
    // Firefox_Mobile: "/videos/permission-firefox.mov",
    Microsoft_Edge: "/videos/permission-edge.mp4",
    // Other_Mobile_Browser: "/videos/permission-mobile-generic.mov",
  };

  return (
    <Result
      status="error"
      title={
        <Typography sx={SectionHeaderText} align="center">
          Access Needed for Survey Participation
        </Typography>
      }
      subTitle={
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={infoText}>
              To participate in this survey, we require access to your{" "}
              <strong>{data}</strong>. This allows us to capture necessary data
              for a more interactive and personalized experience.
            </Typography>
            <Typography sx={infoText}>
              Please enable {data} in your browser settings and try again. If
              you’re unable to enable permissions, you won’t be able to proceed
              with the survey.
            </Typography>
            <Divider style={marginVertical} />
            <Typography sx={infoText}>
              <strong>Steps for Manual Permission Enablement</strong>
            </Typography>

            {videoMap[browser] ? (
              <ReactPlayer
                url={videoMap[browser]}
                playing={true}
                controls
                style={marginTop}
                width={"100%"}
              />
            ) : (
              <Grid container>
                <Grid item xs={12} sx={column}>
                  <Typography sx={infoText}>Chrome: </Typography>
                  <List disablePadding sx={marginLeft}>
                    <ListItem>
                      <ListItemText secondary="Go to Settings > Privacy and Security > Site Settings." />
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary="Find your site under Permissions, and allow access to the camera and microphone." />
                    </ListItem>
                  </List>

                  <Divider />
                </Grid>

                <Grid item xs={12} sx={column}>
                  <Typography sx={infoText}>Firefox: </Typography>
                  <List disablePadding sx={marginLeft}>
                    <ListItem>
                      <ListItemText secondary="Go to Settings > Privacy & Security." />
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary="Scroll to Permissions and find the camera and microphone options." />
                    </ListItem>
                  </List>

                  <Divider />
                </Grid>

                <Grid item xs={12} sx={column}>
                  <Typography sx={infoText}>Safari: </Typography>
                  <List disablePadding sx={marginLeft}>
                    <ListItem>
                      <ListItemText secondary="Go to Safari > Preferences > Websites." />
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary="Set camera and microphone permissions for your site." />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      }
      extra={
        <Button onClick={() => window.location.reload()} type="primary">
          Retry
        </Button>
      }
    />
  );
};

export default PermissionError;
