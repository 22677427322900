/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:43dfb1fe-b2a0-48ff-a5b2-ab880cfe8069",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Tw5Awk6DL",
    "aws_user_pools_web_client_id": "5a69hcoc45f7082ddg3fv4j7ku",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://7hxl4d5iq5hphmocywks6zjoee.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "panelistportal161339dbd2b046bda38ef37cab039023165640-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
