import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { getSurvey } from "../graphql/queries";
import { createResponseUserDetail } from "../graphql/mutations";
import Questions from "./questions";
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import Styles from "./../assets/css/styles";
import "../App.css";
import {
  AnswerText,
  Labels,
  MsgLabelJustify,
  QuestionText,
  StudyHeaderText,
} from "../assets/css/sxStyles";
import QuestionV3 from "./questions-v3";
import Sections from "./Sections";
import { params } from "../common/util";
import {
  Closed_Message,
  Completed_Message,
  Optional_Message,
} from "../common/constant";
import NewQuestions from "./questions-v2";
import {
  getLocalStorage,
  removeItem,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import JournalingQuestions from "./JournalingQuestions";
import SurveyInfo from "../components/SurveyInfo";
import { Keys } from "../common/keys";
import { Result } from "antd";

const Study = ({ onSignUp }) => {
  const classes = Styles();

  const [questions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [isTestingSurvey, setIsTestingSurvey] = useState(false);
  const [data, setData] = useState([]);
  const [userDetail, setUserDetails] = useState({});
  const [surveyStatus, setSurveyStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastInitial, setLastInitial] = useState("");
  const [email, setEmail] = useState("");
  const [disablePanelistId, setDisablePanelistId] = useState(false);

  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [startSurvey, setStartSurvey] = useState(false);
  const [isEmailMode, setIsEmailMode] = useState(false);
  const [optionalMessage, setOptionalMessage] = useState(Optional_Message);
  const [closedMessage, setClosedMessage] = useState(Closed_Message);
  const [completedMessage, setCompletedMessage] = useState(Completed_Message);

  // flag for version2
  const [isVersion1, setIsVersion1] = useState(false);
  const [isVersion2, setIsVersion2] = useState(false);
  const [isVersion3, setIsVersion3] = useState(false);

  // For data upload
  const [isDataUploading, setIsDataUploading] = useState(false);

  // for Journaling
  const [isJournaling, setisJournaling] = useState(false);
  const [startJournaling, setStartJournaling] = useState(false);

  const [surveyData, setSurveyData] = useState("");
  const [open, setOpen] = useState(false);

  let user = useState(getLocalStorage(Keys.User));

  const cardStyle = {
    // backgroundImage: `url("https://picsum.photos/seed/picsum/500/400")`,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    setEmail(user[0].emailHash);
    setFirstName(user[0].firstName);
    setLastInitial(user[0].lastInitial);
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (params.test) {
        setButtonEnabled(true);
        setIsTestingSurvey(true);
        setLocalStorage(Keys.ScreenoutData, []);
      }

      if (params.pid) setDisablePanelistId(true);
      if (user[0].emailHash) setIsDataUploading(true);

      const response = await API.graphql({
        query: getSurvey,
        variables: { id: params.id },
        authMode: "AWS_IAM",
      });

      setSurveyData(response.data.getSurvey);

      const version = response.data.getSurvey.version;
      if (version !== null && version === "v2") setIsVersion2(true);
      else if (version !== null && version === "v3") setIsVersion3(true);
      else setIsVersion1(true);
      const questionString = JSON.parse(response.data.getSurvey.questions);

      setisJournaling(response.data.getSurvey.isJournaling);

      setData(...[], response.data.getSurvey);
      setQuestions(...[], questionString);
      setIsEmailMode(response.data.getSurvey.isEmailMode || false);

      setIsDataUploading(false);

      const cData = JSON.parse(response.data.getSurvey.sections);
      if (cData != null) setSections(cData);

      console.log(questionString, cData);

      if (response.data.getSurvey.optionalMessage)
        setOptionalMessage(response.data.getSurvey.optionalMessage);

      if (response.data.getSurvey.optionalClosedMessage)
        setClosedMessage(response.data.getSurvey.optionalClosedMessage);

      if (response.data.getSurvey.optionalCompletedMessage)
        setCompletedMessage(response.data.getSurvey.optionalCompletedMessage);

      switch (response.data.getSurvey.surveyStatus) {
        case "TESTING":
          setSurveyStatus("Testing");
          setButtonEnabled(true);
          setIsTestingSurvey(true);
          setLocalStorage(Keys.ScreenoutData, []);
          break;
        case "OPEN":
          setSurveyStatus("Open");
          break;
        default:
          setSurveyStatus("Closed");
          break;
      }

      if (questionString.length === 0) setSurveyStatus("Closed");

      if (user[0].emailHash || response.data.getSurvey.isJournaling) {
        let userExist = false;
        let userDetailData;
        response.data.getSurvey.ResponseUserDetails.items.forEach((element) => {
          if (element.emailHash === user[0].emailHash) {
            setUserDetails(...[], element);
            userDetailData = element;
            console.log("User Exists!");
            userExist = true;
          }
        });

        if (!userExist) {
          //verify if existing panelist with same firstName Last initial
          let count = 0;
          response.data.getSurvey.ResponseUserDetails.items.forEach(
            (element) => {
              if (
                element.firstName === user[0].firstName &&
                element.lastInitial[0] === user[0].lastInitial
              )
                count++;
            }
          );

          if (count > 0) user[0].lastInitial = `${user[0].lastInitial}${count}`;
          const userData = await API.graphql({
            query: createResponseUserDetail,
            variables: { input: user[0] },
            authMode: "AWS_IAM",
          });
          setUserDetails(userData.data.createResponseUserDetail);
          userDetailData = userData.data.createResponseUserDetail;
          console.log("User successfully created!");
        }

        if (userDetailData.isFinished && response.data.getSurvey.isJournaling) {
          const answeredData = JSON.parse(userDetailData.answers);

          const condition = (obj1, obj2) =>
            obj2.isInS3 && obj1.title === obj2.question;

          const filteredMediaQuestions = questionString.filter((obj1) =>
            answeredData.some((obj2) => condition(obj1, obj2))
          );

          const ans = questionString
            .map((element, index) => {
              const matchedAnswer = answeredData.find(
                (obj) => obj.question === element.title
              );

              if (matchedAnswer)
                return {
                  question: element.title,
                  answer: matchedAnswer.answer,
                  index,
                  type: questionString[index].answerType,
                };
            })
            .filter(Boolean);

          setLocalStorage(
            `answers-${params.id}-${userDetailData.emailHash}`,
            ans
          );

          setQuestions(...[], filteredMediaQuestions);
          setStartJournaling(true);
        }
        handleModelOpen();
        // setStartSurvey((prevState) => !prevState);
      }
    }

    fetchData().catch((error) => {
      console.error("Error:", error);
    });

    setIsDataUploading(true);
  }, [setQuestions, setisJournaling]);

  useEffect(() => {
    if (
      (firstName.length > 0 && lastInitial.length > 0 && email.length > 3) ||
      isTestingSurvey
    )
      setButtonEnabled(true);
    else setButtonEnabled(false);
  }, [firstName, lastInitial, isTestingSurvey, email]);

  const validateInput = (val) => {
    const letter = /^[a-zA-Z]+$/;
    if (val.match(letter) || val === "") return true;
    else return false;
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case "FirstN":
        if (validateInput(value)) {
          setFirstName(value);
          user[0].firstName = `${value.trim()[0] || "".toUpperCase()}${value
            .trim()
            .slice(1)}`;
        }
        break;
      case "LastN":
        if (validateInput(value)) {
          setLastInitial(value);
          user[0].lastInitial = value.trim()[0] || "".toUpperCase();
        }
        break;
      default:
        setEmail(value);
        user[0].emailHash = value;
        break;
    }
    setLocalStorage(Keys.User, user[0]);
  };

  const handleModelOpen = () => setOpen(true);

  const onSubmit = async () => {
    // setStartSurvey(!startSurvey);
    setOpen(false);
    setIsDataUploading(true);

    if (!isTestingSurvey) {
      try {
        let userExist = false;
        let userDetailData;

        data.ResponseUserDetails.items.forEach((element) => {
          if (element.emailHash === user[0].emailHash) {
            setUserDetails(...[], element);
            userDetailData = element;
            console.log("User Exists!");
            userExist = true;
          }
        });

        if (!userExist) {
          //verify if existing panelist with same firstName Last initial
          let count = 0;
          data.ResponseUserDetails.items.forEach((element) => {
            if (
              element.firstName === user[0].firstName &&
              element.lastInitial[0] === user[0].lastInitial
            )
              count++;
          });
          if (count > 0) user[0].lastInitial = `${user[0].lastInitial}${count}`;
          const userData = await API.graphql({
            query: createResponseUserDetail,
            variables: { input: user[0] },
            authMode: "AWS_IAM",
          });
          setUserDetails(userData.data.createResponseUserDetail);
          userDetailData = userData.data.createResponseUserDetail;
          console.log("User successfully created!");
        } else console.log("User Exists");

        if (userDetailData.isFinished && data.isJournaling) {
          const answeredData = JSON.parse(userDetailData.answers);

          const condition = (obj1, obj2) =>
            obj2.isInS3 && obj1.title === obj2.question;

          const filteredMediaQuestions = questions.filter((obj1) =>
            answeredData.some((obj2) => condition(obj1, obj2))
          );

          const ans = questions
            .map((element, index) => {
              const matchedAnswer = answeredData.find(
                (obj) => obj.question === element.title
              );

              if (matchedAnswer)
                return {
                  question: element.title,
                  answer: matchedAnswer.answer,
                  index,
                  type: questions[index].answerType,
                };
            })
            .filter(Boolean);

          setLocalStorage(
            `answers-${params.id}-${userDetailData.emailHash}`,
            ans
          );
          setQuestions(...[], filteredMediaQuestions);
          setStartJournaling(true);
        }

        setIsDataUploading(false);
        setStartSurvey(!startSurvey);
      } catch (err) {
        console.log("error: ", err);
      }
    } else {
      // window.localStorage.clear();
      removeItem(`answers-${params.id}-${userDetail.emailHash}`);
      setIsDataUploading(false);
      setStartSurvey(!startSurvey);
    }
  };

  if (startJournaling && !isTestingSurvey && surveyStatus !== "Closed")
    return (
      <JournalingQuestions
        QuestionsData={questions}
        userData={userDetail}
        sectionData={sections}
        onSignUp={isEmailMode ? onSignUp : null}
        completedMsg={completedMessage}
        timeStamp={Math.floor(new Date().getTime() / 1000)}
      />
    );

  if (startSurvey && userDetail) {
    return (
      <>
        {isVersion3 && sections.length > 1 ? (
          <Sections
            QuestionsData={questions}
            userData={userDetail}
            sectionData={sections}
            onSignUp={isEmailMode ? onSignUp : null}
            completedMsg={completedMessage}
            isJournaling
          />
        ) : (
          <QuestionV3
            QuestionsData={questions}
            userData={userDetail}
            onSignUp={isEmailMode ? onSignUp : null}
            completedMsg={completedMessage}
            isJournaling
          />
        )}

        {isVersion2 && (
          <NewQuestions
            QuestionsData={questions}
            userData={userDetail}
            onSignUp={isEmailMode ? onSignUp : null}
          />
        )}

        {isVersion1 && (
          <Questions
            QuestionsData={questions}
            userData={userDetail}
            onSignUp={isEmailMode ? onSignUp : null}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="card" style={cardStyle}>
        <SurveyInfo data={surveyData} enabled={open} handleSubmit={onSubmit} />
        {surveyStatus !== "Closed" ? (
          <Grid container>
            <Grid container p={1} display={"flex"} flexDirection={"column"}>
              <Typography sx={StudyHeaderText}>{data.title}</Typography>
              <Typography sx={AnswerText}>
                Welcome. To begin, please sign in.
              </Typography>
            </Grid>

            <div>
              <form className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="margin-dense"
                      margin="dense"
                      label="First Name"
                      error={false}
                      name="FirstN"
                      inputProps={{ style: AnswerText }}
                      value={firstName}
                      variant="outlined"
                      fullWidth
                      onChange={onInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="margin-dense"
                      margin="dense"
                      label="Last Name Initial"
                      name="LastN"
                      error={false}
                      inputProps={{ style: AnswerText }}
                      value={lastInitial}
                      variant="outlined"
                      fullWidth
                      onChange={onInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="margin-dense"
                      margin="dense"
                      error={false}
                      disabled={disablePanelistId}
                      label={isEmailMode ? "Email" : "panelistID"}
                      name={isEmailMode ? "Email" : "panelistID"}
                      value={email}
                      inputProps={{ style: AnswerText }}
                      variant="outlined"
                      fullWidth
                      onChange={onInputChange}
                    />
                  </Grid>
                  <br />
                  <br />

                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      gutterBottom
                      component="div"
                      sx={Labels}
                      style={{
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      By clicking on "Sign In" you agree to participate in this
                      study, which is intended for market research and product
                      demonstration purposes only. {optionalMessage}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {!isDataUploading ? (
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.margin}
                        disabled={!buttonEnabled}
                        onClick={handleModelOpen}
                      >
                        <Typography sx={AnswerText}>Sign In</Typography>
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        ) : (
          <Result
            status="error"
            title={<Typography sx={QuestionText}>Survey Closed</Typography>}
            subTitle={
              <Typography sx={MsgLabelJustify}>
                <br />
                Thank you for your interest in participating in{" "}
                <strong>{data.title}</strong>. Unfortunately, this survey is now
                closed. We look forward to your participation in future
                opportunities.
                <br />
                {closedMessage} <br />
              </Typography>
            }
          />
        )}
      </div>
    );
  }
};

export default Study;
