export const Screen_Out_Message =
  "Based on your responses, you do not meet the criteria for this survey. We appreciate your effort and hope you'll participate in future surveys!";

export const Optional_Message = "";
//"Payment of $15 in the form of an Amazon gift card is sent to the email you provide within 1-3 days after successfully completing the study.";

export const Closed_Message =
  "Thank you for your interest in our survey. We have closed the study and are no longer accepting responses. We appreciate your participation and value your feedback.";

export const Completed_Message =
  "Thank you for completing the survey. Your feedback is valuable to us and helps us improve our services. We appreciate your time and participation.";

export const OnlyMedia = ["audio", "video", "text", "multi-media", "interview"];

export const Tech_Error_Message =
  "We are experiencing some technical difficulties. Our servers are currently unavailable, but we are working to resolve the issue. Please try again in a few minutes.";

export const Max_TimeOut = 5 * 60 * 1000;

export const problematicPlatforms = [
  "Android Facebook platform",
  "Android Messenger platform",
  "Android Instagram platform",
];
