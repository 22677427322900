import React, { useEffect, useState, useRef } from "react";

import { Grid, Button, Typography } from "@mui/material";

// for audio recording
import MicRecorder from "../components/MicRecorder";
import ReactPlayer from "react-player/lazy";

// for icons
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";

import CountDownTimer from "../components/CountDownTimer";

// Amplify upload
import { Storage } from "aws-amplify";

// css
import { AnswerText, GridContainer, NoteText } from "../assets/css/sxStyles";
import "../App.css";
import "antd/dist/antd.css";
import { Progress } from "antd";
import { params } from "../common/util";

const AudioCapture = (props) => {
  // Audio
  const [audioFile, setAudioFile] = useState();
  const [blobURL, setBlobUrl] = useState(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const audioMimeType = "audio/mp3";
  const recorder = useRef(null); //Recorder
  const [isPlaying, setIsPlaying] = useState(false);

  // timer for recording audio
  const hoursMinSecs = { hours: 0, minutes: 0, seconds: 0 };

  const [isTesting, setIsTesting] = useState(false);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);
  const userData = props.userData;

  useEffect(() => {
    if (Object.keys(props.userData).length === 0) setIsTesting(true);
    else setIsTesting(false);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  // Audio recorder
  useEffect(() => {
    const initializeRecorder = async () => {
      try {
        await recorder.current.init();
      } catch (error) {
        console.error("Error initializing recorder: ", error);
      }
    };
    //Declares the recorder object and stores it in ref
    recorder.current = new MicRecorder({ bitRate: 128 });
    initializeRecorder();
  }, []);

  useEffect(() => {
    if (props.isPromptMediaPlaying) setIsPlaying(false);
  }, [props.isPromptMediaPlaying]);

  const startRecording = () => {
    props.onRecording(true);
    recorder.current.start();
    setIsAudioRecording(true);
  };

  const stopRecording = () => {
    props.onRecording(false);
    setIsAudioRecording(false);
    recorder.current
      .stop()
      .then((blob) => {
        const newBlobUrl = URL.createObjectURL(blob); //generates url from blob
        setBlobUrl(newBlobUrl); //refreshes the page

        const newFileName = `${params.id}/${props.data}-${userData.firstName}${userData.lastInitial}.mp3-`;
        if (props.nextEnabled)
          props.onHandlingData(blobToFile(blob, newFileName));
        setAudioFile(blobToFile(blob, newFileName));
      })
      .catch((e) => console.error(e));
  };

  const onStartPlaying = () => {
    setIsPlaying(true);
    props.onPlaying(true);
  };

  const onStopPlaying = () => {
    setIsPlaying(false);
    props.onPlaying(false);
  };

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const handleSubmit = async (event) => {
    const userData = props.userData;
    let newFileName = `${params.id}/${props.data.title}-${userData.firstName}${userData.lastInitial}.mp3`;

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, audioFile, {
          contentType: audioMimeType, // contentType is optional
          progressCallback(progress) {
            setProgress(((progress.loaded / progress.total) * 100).toFixed(0));
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        newFileName = result.key;
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }

    event.target = {
      name: props.data.question,
      value: newFileName,
    };
    props.onChange(event);
  };

  return (
    <Grid container>
      {blobURL ? (
        <Grid item xs={12}>
          <Grid item xs={12} className="audio-container">
            <ReactPlayer
              playsinline={true}
              playing={isPlaying}
              className="audio-prompt-container"
              onPlay={onStartPlaying}
              onPause={onStopPlaying}
              onEnded={onStopPlaying}
              width="300px"
              height="50px"
              url={blobURL}
              controls
            />
          </Grid>

          <Grid item xs={12} width={"100%"} className="button-container">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs="auto">
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<AutorenewOutlinedIcon style={{ fontSize: 20 }} />}
                  size="small"
                  disabled={isProgressBar}
                  onClick={() => {
                    setBlobUrl(null);
                    setIsAudioRecording(false);
                    if (props.nextEnabled) props.onHandlingData(null);
                  }}
                >
                  <Typography sx={AnswerText}>
                    Re{`${String.fromCodePoint(8209)}`}do
                  </Typography>
                </Button>
              </Grid>

              {!props.nextEnabled && !props.isSingleSection && (
                <Grid item xs="auto">
                  {isProgressBar ? (
                    <Progress
                      type="circle"
                      percent={progress}
                      width={50}
                      strokeWidth={5}
                      strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={
                        <BackupOutlinedIcon style={{ fontSize: 20 }} />
                      }
                      size="small"
                      disabled={isProgressBar}
                      onClick={handleSubmit}
                    >
                      <Typography sx={AnswerText}>Submit</Typography>{" "}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={NoteText}>
              Note: Click on {!props.isLast ? "next" : "submit"} to upload the
              recording
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {isAudioRecording ? (
            <Grid item xs={12} sx={GridContainer}>
              <CountDownTimer hoursMinSecs={hoursMinSecs} />
            </Grid>
          ) : (
            <Grid item xs={12} sx={GridContainer}>
              <div>
                <span className="timer-text">00:00</span>
              </div>
            </Grid>
          )}

          <Grid item xs={12} width={"100%"} className="button-container">
            {!isAudioRecording ? (
              <Button
                variant="outlined"
                color="success"
                startIcon={
                  <RadioButtonCheckedOutlinedIcon style={{ fontSize: 20 }} />
                }
                onClick={startRecording}
                disabled={props.disabled}
                size="small"
              >
                <Typography sx={AnswerText}>Record</Typography>
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                onClick={stopRecording}
                startIcon={<StopCircleOutlinedIcon style={{ fontSize: 20 }} />}
                size="small"
              >
                <Typography sx={AnswerText}>Stop</Typography>
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography sx={NoteText}>
              Note: Click on record to start recording
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AudioCapture;
