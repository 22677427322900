import React, { useEffect, useState, useCallback } from "react";
import { AnswerText } from "../assets/css/sxStyles";

import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  Button,
  FormControlLabel,
  Typography,
} from "@mui/material";

import { Labels } from "../assets/css/sxStyles";

import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

import VideoCapture from "../Models/VideoCapture";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomRating from "./CustomRating";
import CustomSelect from "./CustomSelect";
import AudioCapture from "../Models/AudioCapture";
import TextCapture from "../Models/TextCapture";
import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import StimulusModal from "./stimulusModal";
import { capitalizeString, checkIfAnswered, params } from "../common/util";
import { Keys } from "../common/keys";

const Answers = (props) => {
  const [question] = useState(props.questionData);

  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isRadio, setIsRadio] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isRankedSelect, setIsRankedSelect] = useState(false);
  const [isMultiMedia, setIsMultiMedia] = useState(false);
  const [multiMediaValue, setMultiMediaValue] = useState("");

  const [isAnswerDisabled, setIsAnswerDisabled] = useState(props.isAnsDisabled);

  const [multimediaOptions, setMultimediaOptions] = useState([]);
  const [isSingleMultimediaOption, setSingleMultimediaOption] = useState(false);

  const [value, setValue] = useState("");
  const [optionText, setOptionText] = useState("");
  const [textValue, setTextValue] = useState("");

  const [isLastSelectedAnswerOpen, setIsLastSelectedAnswerOpen] =
    useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [isAnswered] = useState(
    checkIfAnswered(props.userData.emailHash, props.index)
  );

  const [isAnsPlaying, setIsAnsPlaying] = useState(false);
  const [isSkippable] = useState(props.questionData.isSkippable || false);

  const [permissions] = useState(getLocalStorage(Keys.Permission));

  useEffect(() => {
    setIsAnswerDisabled(props.isAnsDisabled);
    setIsAutoPlay(false);

    if (!isAnswered && props.questionData.promptType === "text")
      if (["video", "audio"].includes(props.questionData.stimulusType))
        setIsAutoPlay(true);
  }, [
    props.isMediaPlaying,
    setIsAnswerDisabled,
    isAnswered,
    props.isAnsDisabled,
    props.questionData,
  ]);

  useEffect(() => {
    const storedAns = getLocalStorage(
      `answers-${params.id}-${props.userData.emailHash}`
    );
    setIsLastSelectedAnswerOpen(false);

    if (storedAns !== null) {
      const ansIndex = storedAns.findIndex((item) => item.id === props.index);
      if (storedAns[ansIndex]?.answer !== undefined) {
        setValue(storedAns[ansIndex].answer);
        setTextValue(storedAns[ansIndex].textVal);

        if (props.questionData.answersOpen) {
          if (props.questionData.answerType === "single-Select") {
            const check = props.questionData.answers.includes(
              storedAns[ansIndex].answer
            );
            if (!check) {
              setIsLastSelectedAnswerOpen(true);
              setOptionText(storedAns[ansIndex].answer);
            }
          } else {
            const answerOptionsList = props.questionData.answers;
            let answersData = [];

            answersData = storedAns[ansIndex].answer.split(";");
            let uncheckedData = [];
            let checkedData = [];
            answersData.forEach((element) => {
              if (!answerOptionsList.includes(element))
                uncheckedData.push(element);
              else checkedData.push(element);
            });

            if (uncheckedData.length > 0 && uncheckedData[0] !== "") {
              checkedData.push(answerOptionsList[answerOptionsList.length - 1]);
              setIsLastSelectedAnswerOpen(true);
              setOptionText(uncheckedData.join(","));
            }
          }
        }
      }
    }
  }, [props]);

  useEffect(() => {
    setIsCheckbox(false);
    setIsAudio(false);
    setIsRadio(false);
    setIsText(false);
    setIsVideo(false);
    setIsRating(false);
    setIsRankedSelect(false);
    switch (question.answerType) {
      case "video":
        setIsVideo(true);
        break;
      case "audio":
        setIsAudio(true);
        break;
      case "text":
        setIsText(true);
        break;
      case "single-Select":
        setIsRadio(true);
        break;
      case "multi-Select":
        setIsRankedSelect(false);
        setIsCheckbox(true);
        break;
      case "rating":
        setIsRating(true);
        break;
      case "ranked-Select":
        setIsRankedSelect(true);
        setIsCheckbox(true);
        break;
      case "multi-media":
        setIsMultiMedia(true);
        break;
      default:
        break;
    }
  }, [question.answerType]);

  useEffect(() => {
    if (isMultiMedia) {
      let medialist = [];

      question.answers.forEach((element) => {
        if (element) {
          medialist.push(element);
        }
      });

      let singleOption = false;

      if (medialist.length === 1) {
        singleOption = true;
      } else if (medialist.length === 2) {
        if (
          medialist.includes("text") &&
          !permissions.video &&
          !permissions.audio
        ) {
          singleOption = true;
        } else if (!permissions.video && permissions.audio) {
          singleOption = true;
        }
      } else {
        if (!permissions.audio) {
          singleOption = true;
        }
      }

      setSingleMultimediaOption(singleOption);

      setMultimediaOptions(...[], medialist);
      if (
        medialist.indexOf("video") > -1 &&
        permissions.video &&
        permissions.audio
      ) {
        setMultiMediaValue("video");
        setIsVideo(true);
      } else if (medialist.indexOf("audio") > -1 && permissions.audio) {
        setMultiMediaValue("audio");
        setIsAudio(true);
      } else {
        setMultiMediaValue("text");
        setIsText(true);
      }
    }
  }, [isMultiMedia]);

  const nextQuestion = useCallback(
    (file) => {
      console.log(file);
      let answerData = [];
      let valueString = file;
      let ansD = getLocalStorage(
        `answers-${params.id}-${props.userData.emailHash}`
      );
      if (ansD !== null) {
        answerData = ansD;
        const answerListVal = ansD.find(function (element) {
          return element.question === question.title;
        });

        if (answerListVal != null) {
          answerData = answerData.map((p) =>
            p.question === question.title
              ? { ...p, answer: valueString, textVal: isText ? textValue : "" }
              : p
          );
        } else {
          answerData.push({
            question: question.title,
            answer: valueString,
            textVal: textValue,
            id: props.index,
            type: question.answerType,
          });
        }
      } else {
        answerData.push({
          question: question.title,
          answer: valueString,
          textVal: textValue,
          id: props.index,
          type: question.answerType,
        });
      }

      setLocalStorage(
        `answers-${params.id}-${props.userData.emailHash}`,
        answerData
      );

      props.onChange(props.index + 1);
    },
    [textValue]
  );

  const handleSelect = (e) => {
    setValue(e.target.value);
    const lastAnsIndex = props.questionData.answers.length - 1;
    if (isCheckbox) {
      let checkedArray = e.target.value.split(";");
      if (props.questionData.answersOpen) {
        if (checkedArray.includes(props.questionData.answers[lastAnsIndex]))
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
      }
    } else {
      if (e.target.value === "") return;
      if (props.questionData.answersOpen) {
        if (props.questionData.answers[lastAnsIndex] === e.target.value)
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
          nextQuestion(e.target.value);
        }
      } else nextQuestion(e.target.value);
    }
  };

  const handleSkip = () => nextQuestion("");

  const handleOptionSubmit = () => {
    let selectValue = "";
    const lastAnsIndex = question.answers.length - 1;
    if (isCheckbox) {
      let checkedArray = value.split(";");
      let filteredArr = checkedArray.filter(
        (item) => item !== question.answers[lastAnsIndex]
      );

      const answersMismatched = filteredArr.filter((element) =>
        question.answers.includes(element)
      );

      answersMismatched.push(optionText);
      if (question.answersOpen) {
        selectValue = answersMismatched
          .filter((element) => element !== "")
          .join(";");
      } else {
        selectValue = checkedArray
          .filter((element) => element !== "")
          .join(";");
      }
    } else {
      selectValue = optionText;
    }
    nextQuestion(selectValue);
  };

  const setMediaRecord = (e) => {
    setIsRecording(e);
    props.onMediaRecording(e);
  };

  const setMediaPlaying = (e) => {
    setIsAnsPlaying(e);
    props.onAnsMediaPlaying(e);
  };

  const handleChange = (e) => nextQuestion(e.target.value);

  const setAnswerOption = (val) => {
    setIsCheckbox(false);
    setIsAudio(false);
    setIsRadio(false);
    setIsText(false);
    setIsVideo(false);
    setIsRating(false);

    switch (val) {
      case "video":
        setIsVideo(true);
        break;
      case "audio":
        setIsAudio(true);
        break;
      case "text":
        setIsText(true);
        break;
      case "single-Select":
        setIsRadio(true);
        break;
      case "multi-Select":
        setIsCheckbox(true);
        break;
      case "rating":
        setIsRating(true);
        break;
      case "multi-media":
        setIsMultiMedia(true);
        break;
      default:
        break;
    }
  };

  return (
    <Grid container gap={1}>
      {question.stimulusType && (
        <Grid item xs={12}>
          <StimulusModal
            type={capitalizeString(question.stimulusType)}
            url={question.stimulus}
            allow={props.isMediaPlaying || isRecording || isAnsPlaying}
            autoPlay={isAutoPlay}
          />
        </Grid>
      )}

      {isMultiMedia && !isSingleMultimediaOption && (
        <RadioGroup aria-label={question} value={multiMediaValue}>
          <Typography sx={Labels}>
            Please select one option to answer the above question
          </Typography>
          <Grid container>
            {multimediaOptions.map((item, i) => {
              if (
                (item === "video" && permissions.video && permissions.audio) ||
                (item === "audio" && permissions.audio) ||
                item === "text"
              )
                return (
                  <Grid item xs={4} key={i}>
                    <FormControlLabel
                      value={item.toString().trim()}
                      control={<Radio />}
                      disabled={isAnswerDisabled}
                      label={
                        <Typography sx={AnswerText}>
                          {capitalizeString(item.toString())}
                        </Typography>
                      }
                      onChange={(e) => {
                        setMultiMediaValue(e.target.value);
                        setAnswerOption(e.target.value);
                      }}
                    />
                  </Grid>
                );
            })}
          </Grid>
        </RadioGroup>
      )}

      {isVideo && (
        <VideoCapture
          disabled={isAnswerDisabled}
          onChange={handleChange}
          data={question}
          isSingleSection={false}
          isPromptMediaPlaying={props.isMediaPlaying}
          userData={props.userData}
          onRecording={setMediaRecord}
          onPlaying={setMediaPlaying}
          nextEnabled={false}
        />
      )}

      {isAudio && (
        <AudioCapture
          disabled={isAnswerDisabled}
          onChange={handleChange}
          data={question}
          isSingleSection={false}
          isPromptMediaPlaying={props.isMediaPlaying}
          onRecording={setMediaRecord}
          onPlaying={setMediaPlaying}
          userData={props.userData}
          nextEnabled={false}
        />
      )}

      {isText && (
        <TextCapture
          disabled={isAnswerDisabled}
          onChange={handleChange}
          data={question}
          value={textValue}
          onTextValueChange={(e) => setTextValue(e.target.value)}
          userData={props.userData}
          onPlaying={setMediaPlaying}
        />
      )}

      {isRadio && (
        <Grid item xs={12} width="100%" style={{ gap: "2em" }}>
          <CustomSelect
            onChange={handleSelect}
            data={question}
            value={value}
            disabled={isAnswerDisabled}
          />
          {isLastSelectedAnswerOpen && (
            <Grid container paddingY={2} gap={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label=" "
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  size="small"
                  name="optionText"
                  value={optionText}
                  disabled={isAnswerDisabled}
                  placeholder="Please enter your option"
                  onChange={(e) => setOptionText(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<BackupOutlinedIcon style={{ fontSize: 20 }} />}
                  size="small"
                  disabled={optionText.length > 3 ? false : true}
                  onClick={handleOptionSubmit}
                >
                  <Typography sx={AnswerText}>Submit</Typography>{" "}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {isRating && (
        <Grid container>
          <CustomRating
            onChange={handleChange}
            data={question}
            value={value}
            disabled={isAnswerDisabled}
          />
        </Grid>
      )}

      {isCheckbox && (
        <Grid container gap={1}>
          <Grid item xs={12} width={"100%"}>
            <CustomMultiSelect
              onChange={handleSelect}
              data={question}
              value={value}
              isRankedSelect={isRankedSelect}
              disabled={isAnswerDisabled}
            />

            {isLastSelectedAnswerOpen && (
              <Grid container paddingY={1} gap={1}>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    For multiple answer seperate the words by comma ,
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    label=" "
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    size="small"
                    name="optionText"
                    value={optionText}
                    disabled={isAnswerDisabled}
                    placeholder="Please enter your option"
                    onChange={(e) => setOptionText(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} marginY={1}>
            <Button
              variant="outlined"
              startIcon={<BackupOutlinedIcon style={{ fontSize: 20 }} />}
              size="small"
              onClick={handleOptionSubmit}
            >
              <Typography sx={AnswerText}>Submit</Typography>{" "}
            </Button>
          </Grid>
        </Grid>
      )}

      {isSkippable && (
        <Grid container paddingY={2} gap={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              endIcon={
                <KeyboardDoubleArrowRightOutlinedIcon
                  style={{ fontSize: 20 }}
                />
              }
              size="small"
              onClick={handleSkip}
            >
              <Typography sx={AnswerText}>Skip</Typography>{" "}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Answers;
