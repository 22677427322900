import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Modal } from "@mui/material";
import {
  AnswerText,
  StudyHeaderText,
  StudyText,
  studyStartButton,
} from "../assets/css/sxStyles";
import PermissionError from "./PermissionError";
import {
  determinePermissions,
  formatDate,
  getPermission,
  getTimeToComplete,
  requestPermissions,
} from "../common/util";
import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import { Keys } from "../common/keys";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const SurveyInfo = ({ data, enabled, handleSubmit }) => {
  const [open, setOpen] = useState(false);
  const [isPermisionInfo, setPermisionInfo] = useState(false);
  const [isPermissionGranted, setPermission] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [checkPermissions, setCheckPermissions] = useState([]);

  const avPermission = getLocalStorage(Keys.Permission);

  useEffect(() => {
    setOpen(enabled);
  }, [enabled]);

  useEffect(() => {
    if (data && data?.questions) {
      const questionsData = JSON.parse(data.questions);
      setQuestions(questionsData);
      setCheckPermissions(determinePermissions(questionsData));
    }
  }, [data]);

  const checkIfPermissions = (permissions) => {
    console.log(permissions);
    if (
      checkPermissions.video === "must" &&
      !permissions.video &&
      !permissions.audio
    )
      setPermission(false);
    else if (checkPermissions.audio === "must" && !permissions.audio)
      setPermission(false);
    else handleSubmit();
  };

  const handleTakeMeIn = () => {
    setPermisionInfo(true);
    if (avPermission !== null) checkIfPermissions(avPermission);
  };

  const handlePermission = async () => {
    console.log(checkIfPermissions);
    const permissions = await requestPermissions(checkPermissions);
    setLocalStorage(Keys.Permission, permissions);
    checkIfPermissions(permissions);
  };

  const avPermissionString = () => {
    const permissionData = getPermission(questions);
    if (!permissionData.video) return "audio permissions";
    else return "audio and video permissions";
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Grid container sx={{ ...style, width: "50em", maxWidth: "80vw" }}>
        {!isPermisionInfo ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={StudyHeaderText}>{data.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={StudyText}>
                {data.optionalMessage} <br />
                This survey will take approximately{" "}
                <b>{getTimeToComplete(questions)} minutes</b> of your time, and
                your responses will be kept <b>completely private</b>. We value
                your input and believe that your participation will make a
                significant impact. <br />
                The survey will close on <b>{formatDate(data.stopDate)}</b>.
                Please make sure to complete it before then. <br />
                <br />
                Ready to begin? Click 'Take Me In' below to begin the survey!
              </Typography>
            </Grid>

            <Grid item xs={12} sx={studyStartButton}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                // className={classes.margin}
                onClick={handleTakeMeIn}
              >
                <Typography sx={AnswerText}>Take Me In</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            {isPermissionGranted === null && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={StudyHeaderText}>Important Notice</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={StudyText}>
                    When you click the button below, the app will request access
                    to your microphone and/or camera. These permissions are
                    needed to complete certain parts of the survey. You can
                    choose to allow or deny access, but please note that denying
                    access may limit your ability to answer specific questions.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "-4px", marginTop: "20px" }}
                    onClick={handlePermission}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            )}
            {isPermissionGranted === false && (
              <Grid container>
                <PermissionError data={avPermissionString()} />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default SurveyInfo;
