import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  Button,
  FormControlLabel,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";

import { Progress, Result } from "antd";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

// for s3 file upload
import { Storage } from "aws-amplify";

import StimulusModal from "../components/stimulusModal";
import CustomSelect from "../components/CustomSelect";
import CustomMultiSelect from "../components/CustomMultiSelect";
import CustomRating from "../components/CustomRating";

// for virtual interviewer
import VideoChat from "../components/VirtualInterviewer/VideoChat.js";

import {
  clear,
  getLocalStorage,
  removeItem,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import { API } from "aws-amplify";

// css
import Styles from "../assets/css/styles";
import {
  QuestionText,
  AnswerText,
  CardContainer,
  Labels,
  MsgLabelJustify,
  marginTop,
} from "../assets/css/sxStyles";
import "../App.css";
import "antd/dist/antd.css";
import { params, capitalizeString, checkIfAnswered } from "../common/util";
import PromptQuestion from "../components/PromptQuestion.js";
import VideoCapture from "../Models/VideoCapture.js";
import AudioCapture from "../Models/AudioCapture.js";
import { OnlyMedia } from "../common/constant.js";
import { updateResponseUserDetail } from "../graphql/mutations.js";
import { Keys } from "../common/keys.js";

const cardStyle = {
  padding: "0 1rem 1em 1rem",
};

const QuestionV3 = ({
  QuestionsData,
  userData,
  onSignUp,
  completedMsg,
  isJournaling,
}) => {
  const classes = Styles();

  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  );
  window.onresize = () => {
    if (window.innerWidth < window.innerHeight && !isPortrait)
      setIsPortrait(!isPortrait);
  };
  const [questions] = useState(QuestionsData);
  const [answersList, setAnswersList] = useState([]);
  // answer Type
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isRadio, setIsRadio] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isMultiMedia, setIsMultiMedia] = useState(false);
  const [isRankedSelect, setIsRankedSelect] = useState(false);
  const [isInterview, setIsInterview] = useState(false);
  const [multiMediaValue, setMultiMediaValue] = useState("");

  const [multimediaOptions, setMultimediaOptions] = useState([]);
  const [isSingleMultimediaOption, setSingleMultimediaOption] = useState(false);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(
    userData.isFinished
  );

  const [isTesting, setIsTesting] = useState(false);
  const [isStimulusDisabled, setIsStimulusDisabled] = useState(false);

  // Answer option
  const [index, setIndex] = useState(0);

  const [value, setValue] = useState("");
  const [textValue, setTextValue] = useState("");

  // set prev button
  const [isPrev, setIsPrev] = useState(false);
  const [isLast, setIsLast] = useState(true);

  // For prompt messages
  const [isPromptMediaPlaying, setIsPromptMediaPlaying] = useState(false);

  // For all Media
  const [isAnsMediaPlaying, setIsAnsMediaPlaying] = useState(false);
  const [isMediaRecording, setIsMediaRecording] = useState(false);

  const [mediaFileData, setMediaFileData] = useState(null);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);

  // for disabling answers
  const [isAnswerDisabled, setIsAnswerDisabled] = useState(true);

  // For open answer
  const [isLastSelectedAnswerOpen, setIsLastSelectedAnswerOpen] =
    useState(false);

  const [isSkippable, setIsSkippable] = useState(false);

  const [isNextEnabled, setIsNextEnabled] = useState(true);
  const [optionText, setOptionText] = useState("");
  const [questionsProgress, setQuestionsProgress] = useState(0);
  const [videoIndex, setVideoIndex] = useState(0);

  // For screening data
  const screeningData = getLocalStorage(Keys.ScreenoutData);

  //for interview
  const [interviewIndex, setInterviewIndex] = useState(0);
  const [interviewInProgress, setInterviewInProgress] = useState(false);

  useEffect(() => {
    setIsSkippable(QuestionsData[index].isSkippable || false);
  }, [index]);

  const setPromptMediaChanges = (state) => setIsPromptMediaPlaying(state);

  const setPlayedOnce = (state) => setIsAnswerDisabled(!state);

  const setCaptureMediaChanges = (state) => setIsAnsMediaPlaying(state);

  const setMediaRecord = (state) => setIsMediaRecording(state);

  const handleMediaData = (data) => setMediaFileData(data);
  const [permissions] = useState(getLocalStorage(Keys.Permission));

  useEffect(() => {
    const nbQuestion = questions.reduce(
      (a, curr) =>
        curr.answerType === "interview" ? a + curr.questionTotal : a + 1,
      0
    );
    const answeredQuestion = questions.reduce((a, curr, idx) => {
      if (idx > index) return a;
      else if (idx === index)
        return curr.answerType === "interview" ? a + interviewIndex : a + 1;
      else
        return curr.answerType === "interview" ? a + curr.questionTotal : a + 1;
    }, 0);

    setQuestionsProgress(
      (((answeredQuestion - 1) / nbQuestion) * 100).toFixed(0)
    );
  }, [index, questions, interviewIndex]);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    var isVideoAvailable = questions.find((e) => e.promptType === "video")
      ? true
      : false;

    if (isVideoAvailable) {
      const videoIds = questions.find((e) => e.promptType === "video").videoIds;
      const vId = videoIds.findIndex((e) => e === params.videoId);
      if (vId != -1) setVideoIndex(vId);
    }
  }, [questions]);

  useEffect(() => {
    if ((isRadio || isCheckbox) && isLastSelectedAnswerOpen)
      if (optionText.length > 1) setIsNextEnabled(false);
      else setIsNextEnabled(true);
    else if ((isRadio || isCheckbox) && value.length > 1)
      setIsNextEnabled(false);
    else if (value != "") setIsNextEnabled(false);
    else setIsNextEnabled(true);
  }, [
    isLastSelectedAnswerOpen,
    isCheckbox,
    isRadio,
    optionText,
    value,
    textValue,
    setIsNextEnabled,
  ]);

  useEffect(() => {
    setIsStimulusDisabled(false);
    if (questions[index].stimulusType)
      if (
        (["video", "audio"].includes(questions[index].promptType) &&
          isMediaRecording) ||
        isPromptMediaPlaying ||
        isAnsMediaPlaying
      )
        setIsStimulusDisabled(true);
  }, [
    isMediaRecording,
    index,
    isPromptMediaPlaying,
    isAnsMediaPlaying,
    questions,
    setIsStimulusDisabled,
  ]);

  useEffect(() => {
    if (isMultiMedia) {
      let medialist = [];

      questions[index].answers.forEach((element) => {
        if (element) medialist.push(element);
      });

      let singleOption = false;

      if (medialist.length === 1) {
        singleOption = true;
      } else if (medialist.length === 2) {
        if (
          medialist.includes("text") &&
          !permissions.video &&
          !permissions.audio
        ) {
          singleOption = true;
        } else if (!permissions.video && permissions.audio) {
          singleOption = true;
        }
      } else {
        if (!permissions.audio) {
          singleOption = true;
        }
      }

      setSingleMultimediaOption(singleOption);
      setMultimediaOptions(...[], medialist);
      if (
        medialist.indexOf("video") > -1 &&
        permissions.video &&
        permissions.audio
      ) {
        setMultiMediaValue("video");
        setIsVideo(true);
      } else if (medialist.indexOf("audio") > -1 && permissions.audio) {
        setMultiMediaValue("audio");
        setIsAudio(true);
      } else {
        setMultiMediaValue("text");
        setIsText(true);
      }
    }
  }, [index, isMultiMedia, questions, videoIndex, setMultimediaOptions]);

  const getDependentArray = (i) => {
    let dependentData = questions[i].dependencies;
    let isDependent = [];

    let answerData = getLocalStorage(
      `answers-${params.id}-${userData.emailHash}`
    );

    if (answerData !== null && answerData.length > 0) {
      answerData.forEach((element) => {
        for (let ind = 0; ind < dependentData.length; ind++) {
          const answeredData = dependentData[ind];
          if (element.question === answeredData.question) {
            const answeredArray = answeredData.answer;
            let elementAnswerArray = [];

            if (element.type === "multi-Select")
              elementAnswerArray = element.answer.split(";");
            else if (element.type === "rating")
              elementAnswerArray.push(element.answer.split("-")[1]);
            else elementAnswerArray.push(element.answer);

            if (["multi-Select", "single-Select"].includes(element.type)) {
              if (
                ["Other", "Self Identify"].some((item) =>
                  answeredArray.includes(item)
                )
              )
                if (
                  !elementAnswerArray.every((elem) =>
                    questions[element.index].answers.includes(elem)
                  )
                )
                  elementAnswerArray.push(
                    questions[element.index].answers[
                      questions[element.index].answers.length - 1
                    ]
                  );
            }

            if (elementAnswerArray.some((item) => answeredArray.includes(item)))
              isDependent.push(true);
            else isDependent.push(false);
          } else return false;
        }
      });
    }
    return isDependent;
  };

  const checkDependentLastAnswer = useCallback(
    (question, value, i) => {
      let answersLeft = 0;
      if (i < questions.length) {
        for (let j = i; j < questions.length; j++) {
          const element = questions[j];
          if (element.dependencies.length > 0) {
            let isDependent = getDependentArray(j);
            let checker = (arr) => arr.every((v) => v === true);

            if (question.title === element.dependencies[0].question) {
              const arr1 = element.dependencies[0].answer;
              if (question.answerType === "rating")
                if (arr1.includes(value.split("-")[1])) answersLeft += 1;
                else if (question.answerType === "multi-Select") {
                  const arr2 = value.split(";");
                  if (arr1.some((item) => arr2.includes(item)))
                    answersLeft += 1;
                } else if (question.answerType === "single-Select")
                  if (arr1.includes(value)) answersLeft += 1;
            } else if (isDependent.length > 0 && checker(isDependent))
              answersLeft += 1;
          } else answersLeft += 1;
        }
      }
      if (answersLeft > 0) setIsLast(false);
      else setIsLast(true);
    },
    [setIsLast, index, getDependentArray, questions]
  );

  const handleChange = (event) => {
    let lastAnsIndex = 0;

    const curQuestion = questions.find(
      (x) => x.question.toString() === event.target.name.toString()
    );

    const curIndex = questions.findIndex((object) => {
      return object.title === curQuestion.title;
    });

    checkDependentLastAnswer(curQuestion, event.target.value, curIndex + 1);

    if (isCheckbox) {
      let checkedArray = event.target.value.split(";");
      lastAnsIndex = questions[curIndex].answers.length - 1;
      if (questions[curIndex].answersOpen) {
        if (checkedArray.includes(questions[curIndex].answers[lastAnsIndex]))
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
      }
      setValue(checkedArray.join(";"));
    } else if (isRadio) {
      setValue(event.target.value);
      lastAnsIndex = questions[curIndex].answers.length - 1;
      if (questions[curIndex].answersOpen)
        if (questions[curIndex].answers[lastAnsIndex] === event.target.value)
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
    } else setValue(event.target.value);
  };

  useEffect(() => {
    let data = getLocalStorage(`answers-${params.id}-${userData.emailHash}`);

    if (Object.keys(userData).length === 0) setIsTesting(true);
    else setIsTesting(false);

    let dataindex = 0;

    if (data !== null) {
      dataindex = data.length;
      setIndex(dataindex);
      if (dataindex > 1)
        setIsPrev(questions[dataindex - 1].answerType !== "interview");

      setIsSurveyCompleted(userData.isFinished);
    }
    if (dataindex > 0) dataindex = checkDependancy(dataindex, "add");
    else setIsPrev(false);

    checkForLastQuestion(dataindex);
    setAnswersList(data);

    setAnswerOption(questions[dataindex].answerType);
  }, [questions, userData, setIsSurveyCompleted]);

  const checkForLastQuestion = useCallback(
    (id) => {
      let answersLeft = 0;
      if (id < questions.length)
        for (let ind = id + 1; ind < questions.length; ind++) {
          const element = questions[ind];
          if (element.dependencies.length > 0) {
            let isDependent = getDependentArray(ind);
            let checker = (arr) => arr.every((v) => v === true);
            if (isDependent.length > 0 && checker(isDependent))
              answersLeft += 1;
          } else answersLeft += 1;
        }
      if (answersLeft > 0) setIsLast(false);
      else setIsLast(true);
    },
    [questions, setIsLast]
  );

  const checkDependancy = useCallback(
    (i, val) => {
      if (i < questions.length && questions[i].dependencies.length > 0) {
        let isDependent1 = getDependentArray(i);

        if (!isDependent1) return i;

        let checker = (arr) => arr.every((v) => v === true);
        if (isDependent1.length > 0 && checker(isDependent1)) return i;
        else if (val === "add") return checkDependancy(i + 1, "add");
        else return checkDependancy(i - 1, "sub");
      } else return i;
    },
    [questions, params.id, getDependentArray]
  );

  const setOptionalText = (i) => {
    if (answersList && answersList.length > i) {
      let answerVal = answersList.find(
        (x) => x.question === questions[i].title
      ).answer;

      setValue(answerVal);
      if (questions[i].answersOpen) {
        const answers = questions[i].answers;
        if (questions[i].answerType === "single-Select") {
          if (!answers.includes(answerVal)) {
            setIsLastSelectedAnswerOpen(true);
            setOptionText(answerVal);
            setValue(answers[answers.length - 1]);
          }
        } else if (questions[i].answerType === "multi-Select") {
          const answerOptionsList = questions[i].answers;
          let answersData = [];

          answersData = answerVal.split(";");
          let uncheckedData = [];
          let checkedData = [];
          answersData.forEach((element) => {
            if (!answerOptionsList.includes(element))
              uncheckedData.push(element);
            else checkedData.push(element);
          });

          if (uncheckedData.length > 0 && uncheckedData[0] !== "") {
            checkedData.push(answerOptionsList[answerOptionsList.length - 1]);
            setValue(checkedData.join(";"));
            setIsLastSelectedAnswerOpen(true);
            setOptionText(uncheckedData.join(","));
          }
        }
      }
    }
  };

  const resetMediaOptions = () => {
    setIsPromptMediaPlaying(false);
    setIsAnsMediaPlaying(false);
    setValue("");
    setOptionText("");
    setIsMultiMedia(false);
    setIsLastSelectedAnswerOpen(false);

    setMediaFileData(null);
    setIsStimulusDisabled(false);
  };

  const isSubmitInProgress = (e) => setInterviewInProgress(e);
  const handleMediaChange = (e) => console.log(e);

  const handleSkip = () => nextQuestion("");

  const nextQuestion = useCallback(
    async (filename) => {
      let answerData = [];
      let valueString = value;
      if (isLastSelectedAnswerOpen) {
        if (isCheckbox) {
          const lastAnswerOptionsString =
            questions[index].answers[questions[index].answers.length - 1];
          const islastSelected = value
            .split(";")
            .includes(lastAnswerOptionsString);
          if (islastSelected) {
            if (optionText !== "") {
              const stringText = optionText.split(",");
              let vlarr = value.split(";").concat(stringText);
              vlarr = vlarr.filter((e) => e !== lastAnswerOptionsString);
              valueString = [...new Set(vlarr)].join(";");
            } else valueString = "";
          }
        } else valueString = optionText;
      }

      if (answersList && answersList.length !== 0) {
        answerData = answersList;
        if (answersList.length > index) {
          answersList.forEach((obj) => {
            if (obj.question === questions[index].title)
              obj.answer =
                typeof filename === "string" ? filename : valueString;

            let ansD = getLocalStorage(
              `answers-${params.id}-${userData.emailHash}`
            );
            const answerListVal = ansD.find(function (element) {
              return element.question === questions[index].title;
            });

            let val = value;
            if (questions[index].answersOpen) {
              const ansList = questions[index].answers;
              if (questions[index].answerType === "single-Select") {
                if (ansList[ansList.length - 1] === value) val = optionText;
              } else {
                const valList = value.split(";");
                if (valList.includes(ansList[ansList.length - 1])) {
                  valList.filter(function (ele) {
                    return ele !== ansList[ansList.length - 1];
                  });
                  valList.push(optionText);
                  val = [...new Set(valList)].join(";");
                }
              }
            }

            if (answerListVal.answer !== val) {
              var found = answerData.findIndex(function (element) {
                return element.question === questions[index].title;
              });
              answersList.splice(found + 1);
            }
          });

          answerData = answersList;
        } else
          answerData.push({
            question: questions[index].title,
            answer:
              typeof filename === "string" || Array.isArray(filename)
                ? filename
                : valueString,
            index,
            type: questions[index].answerType,
          });
      } else
        answerData.push({
          question: questions[index].title,
          answer:
            typeof filename === "string" || Array.isArray(filename)
              ? filename
              : valueString,
          index,
          type: questions[index].answerType,
        });

      setLocalStorage(`answers-${params.id}-${userData.emailHash}`, answerData);
      setAnswersList(...[], answerData);

      if (index !== questions.length - 1) {
        let i = checkDependancy(index + 1, "add");
        if (i === questions.length) i -= 1;

        setIndex(i);
        resetMediaOptions();
        setAnswerOption(questions[i].answerType);
        setPrevButton(i);
        if (questions[i].answerType === "interview") setIsPrev(false);
        checkForLastQuestion(i);
        setOptionalText(i, answersList);
      }
    },
    [
      answersList,
      index,
      questions,
      userData,
      value,
      checkDependancy,
      isLastSelectedAnswerOpen,
      optionText,
    ]
  );

  const onSubmit = useCallback(
    async (filename) => {
      let answerData = [];

      if (answersList)
        answerData = [...answersList.filter((obj) => obj.answer !== "")];

      answerData.push({
        question: questions[index].title,
        answer:
          typeof filename === "string" || Array.isArray(filename)
            ? filename
            : value,
        index: index,
        type: questions[index].answerType,
      });

      if (screeningData.length > 0) answerData.push(...screeningData);

      let response = {
        emailHash: userData.emailHash,
        surveyID: userData.surveyID,
        answers: JSON.stringify(
          answerData.flatMap((a) => {
            if (a.type === "interview") {
              return a.answer.map((b) => {
                return {
                  question: b.question,
                  answer: b.s3key,
                  isInS3: true,
                  isInterview: true,
                };
              });
            }
            return {
              question: a.question,
              answer: a.answer,
              isInS3: OnlyMedia.includes(a.type),
            };
          })
        ),
        isFinished: true,
      };

      console.log(isTesting, response);

      if (!isTesting) {
        try {
          const updateResponse = await API.graphql({
            query: updateResponseUserDetail,
            variables: { input: response },
            authMode: "AWS_IAM",
          });
          console.log(updateResponse);
          clear();
          setIsSurveyCompleted(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        clear();
        setIsSurveyCompleted(true);
      }
    },
    [answersList, index, questions, userData, value, isTesting]
  );

  const prevQuestion = () => {
    resetMediaOptions();

    let ansD = getLocalStorage(`answers-${params.id}-${userData.emailHash}`);

    let curInd = ansD.findIndex((item) => item.index === index);
    if (curInd === -1) curInd = ansD.length;

    setIsMultiMedia(false);
    setIndex(ansD[curInd - 1].index);
    setPrevButton(ansD[curInd - 1].index);
    if (
      curInd >= 2 &&
      questions[ansD[curInd - 2].index].answerType === "interview"
    )
      setIsPrev(false);
    setValue(ansD[curInd - 1].answer);

    setAnswerOption(questions[ansD[curInd - 1].index].answerType);
    setIsLastSelectedAnswerOpen(false);
    setOptionText("");
    setOptionalText(ansD[curInd - 1].index, answersList);
    checkForLastQuestion(ansD[curInd - 1].index);
  };

  const setPrevButton = (value) =>
    setIsPrev(value > 0 && questions[value - 1]?.answerType !== "interview");

  const setAnswerOption = (val) => {
    setIsCheckbox(false);
    setIsAudio(false);
    setIsRadio(false);
    setIsText(false);
    setIsVideo(false);
    setIsRating(false);
    setIsRankedSelect(false);
    setIsInterview(false);

    switch (val) {
      case "video":
        setIsVideo(true);
        break;
      case "audio":
        setIsAudio(true);
        break;
      case "text":
        setIsText(true);
        break;
      case "single-Select":
        setIsRadio(true);
        break;
      case "multi-Select":
        setIsCheckbox(true);
        setIsRankedSelect(false);
        break;
      case "rating":
        setIsRating(true);
        break;
      case "multi-media":
        setIsMultiMedia(true);
        break;
      case "ranked-Select":
        setIsCheckbox(true);
        setIsRankedSelect(true);
        break;
      case "interview":
        setIsInterview(true);
      default:
        break;
    }
  };

  const finishInterview = (interviewAnswers) => {
    console.log("finishInterview", interviewAnswers, interviewAnswers);
    setValue(interviewAnswers);
    if (!isLast) nextQuestion(interviewAnswers);
    else onSubmit(interviewAnswers);
  };

  const handleDownload = useCallback(async () => {
    const newFileName = `${params.id}/${questions[index].title}-${userData.firstName}${userData.lastInitial}`;

    const file = {
      // `uri` can also be a file system path (i.e. file://)
      uri: new File([mediaFileData], newFileName),
      name: newFileName + ".mp4",
      type: "video/mp4",
    };

    console.log(file);

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(file.name, file.uri, {
          contentType: "video/mp4", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        console.log(result);
        setMediaFileData(null);
        setValue(result.key); //was file.name.
        if (!isLast) nextQuestion(result.key);
        else onSubmit(result.key);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setMediaFileData(null);
      setValue(file.name); //was file.name.
      if (!isLast) nextQuestion(file.name);
      else onSubmit(file.name);
    }
  }, [
    mediaFileData,
    index,
    questions,
    userData,
    nextQuestion,
    onSubmit,
    isTesting,
  ]);

  const onAudioSubmit = async () => {
    const newFileName = `${params.id}/${questions[index].title}-${userData.firstName}${userData.lastInitial}.mp3`;
    setMediaFileData(null);
    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, mediaFileData, {
          contentType: "audio/mp3", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        setValue(result.key); //was file.name
        if (!isLast) nextQuestion(result.key);
        else onSubmit(result.key);

        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      if (!isLast) nextQuestion(newFileName);
      else onSubmit(newFileName);
    }
  };

  const onTextSubmit = async () => {
    const newFileName = `${params.id}/${questions[index].title}-${userData.firstName}${userData.lastInitial}.txt`;
    setMediaFileData(null);
    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, textValue, {
          contentType: "text/plain", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        console.log(result);
        setValue(result.key); //was file.name
        setTextValue("");
        if (!isLast) nextQuestion(result.key);
        else onSubmit(result.key);

        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      setTextValue("");
      if (!isLast) nextQuestion(newFileName);
      else onSubmit(newFileName);
    }
  };

  if (isSurveyCompleted) {
    removeItem(`answers-${params.id}-${userData.emailHash}`);
    removeItem(Keys.User);
    return (
      <div className="app-center">
        <div className="card" style={cardStyle}>
          <Result
            status="success"
            title={
              <Typography sx={QuestionText} align="center">
                Survey Completed
              </Typography>
            }
            subTitle={
              <>
                <Typography sx={MsgLabelJustify}>{completedMsg}</Typography>
                <br />
                {onSignUp && (
                  <Typography sx={MsgLabelJustify}>
                    Are you interested in joining our panelist database for
                    exciting paid study opportunities in the future?
                  </Typography>
                )}
              </>
            }
            extra={
              onSignUp && (
                <Button onClick={onSignUp} type="primary">
                  Sign me up for paid studies!
                </Button>
              )
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="app-center">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          margin={"0px !important"}
        >
          {!isPortrait && (
            <Grid item xs={2} alignItems="center">
              {isPrev ? (
                <Button
                  className={classes.centeredButton}
                  variant="contained"
                  onClick={prevQuestion}
                  disabled={isProgressBar}
                >
                  <Typography sx={AnswerText}>Prev</Typography>
                </Button>
              ) : (
                <div></div>
              )}
            </Grid>
          )}
          <Grid item xs="auto" padding={"0px !important"}>
            <Card sx={CardContainer}>
              <Grid container>
                <Grid item xs={12} paddingRight={"15px"}>
                  <Progress
                    strokeLinecap="butt"
                    strokeColor={{
                      "0%": "#108ee9",
                      "100%": "#87d068",
                    }}
                    percent={questionsProgress}
                  />
                </Grid>
              </Grid>

              <CardContent style={cardStyle}>
                {questions.length > 0 ? (
                  <div className={classes.root}>
                    <Grid container>
                      <Grid item xs={12} padding={0}>
                        <FormControl
                          component="fieldset"
                          style={{ gap: "0.75em" }}
                          fullWidth
                        >
                          <Grid item xs={12} className="prompt-video-container">
                            <Grid item xs={12}>
                              <PromptQuestion
                                pType={questions[index].promptType}
                                quest={questions[index].question}
                                videoId={videoIndex || 0}
                                index={index}
                                isMediaAnswerPlaying={isAnsMediaPlaying}
                                isAnswered={checkIfAnswered(
                                  userData.emailHash,
                                  index
                                )}
                                isMediaRecording={isMediaRecording}
                                onPlayedOnce={setPlayedOnce}
                                onMediaChange={setPromptMediaChanges}
                              />
                            </Grid>
                          </Grid>

                          {questions[index].stimulusType && (
                            <Grid item xs={12}>
                              <StimulusModal
                                type={capitalizeString(
                                  questions[index].stimulusType
                                )}
                                url={questions[index].stimulus}
                                allow={isStimulusDisabled}
                              />
                            </Grid>
                          )}

                          {isMultiMedia && !isSingleMultimediaOption && (
                            <RadioGroup
                              aria-label={questions[index].question}
                              name={questions[index].question}
                              value={multiMediaValue}
                            >
                              <Typography sx={Labels}>
                                Please select one option to answer the above
                                question
                              </Typography>
                              <Grid container>
                                {multimediaOptions.map((item, i) => {
                                  if (
                                    (item === "video" &&
                                      permissions.video &&
                                      permissions.audio) ||
                                    (item === "audio" && permissions.audio) ||
                                    item === "text"
                                  )
                                    return (
                                      <Grid item xs={6} sm={4} key={i}>
                                        <FormControlLabel
                                          value={item.toString().trim()}
                                          control={<Radio />}
                                          disabled={isAnswerDisabled}
                                          label={
                                            <Typography sx={AnswerText}>
                                              {capitalizeString(
                                                item.toString()
                                              )}
                                            </Typography>
                                          }
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            setMultiMediaValue(e.target.value);
                                            setAnswerOption(e.target.value);
                                          }}
                                        />
                                      </Grid>
                                    );
                                })}
                              </Grid>
                            </RadioGroup>
                          )}

                          {isRadio && (
                            <Grid
                              item
                              xs={12}
                              width="100%"
                              style={{ gap: "2em" }}
                            >
                              <CustomSelect
                                onChange={handleChange}
                                data={questions[index]}
                                value={value}
                                disabled={isAnswerDisabled}
                              />
                              {isLastSelectedAnswerOpen && (
                                <div className="option-text">
                                  <TextField
                                    id="outlined-basic"
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    name="optionText"
                                    value={optionText}
                                    disabled={isAnswerDisabled}
                                    placeholder="Please enter your option"
                                    onChange={(e) =>
                                      setOptionText(e.target.value)
                                    }
                                    variant="outlined"
                                  />
                                </div>
                              )}
                            </Grid>
                          )}

                          {isText && (
                            <Grid item xs={12} sx={marginTop} width={"100%"}>
                              <TextField
                                id="outlined-multiline-flexible"
                                name={questions[index].question}
                                multiline
                                disabled={isAnswerDisabled}
                                label="Enter you answer"
                                inputProps={{ style: AnswerText }}
                                className="text-area"
                                placeholder="Enter your text here"
                                maxRows={10}
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                              />
                            </Grid>
                          )}

                          {isRating && (
                            <Grid container>
                              <CustomRating
                                onChange={handleChange}
                                data={questions[index]}
                                value={value}
                                disabled={isAnswerDisabled}
                              />
                            </Grid>
                          )}

                          {isCheckbox && (
                            <Grid item xs={12} width={"100%"}>
                              <CustomMultiSelect
                                onChange={handleChange}
                                data={questions[index]}
                                value={value}
                                isRankedSelect={isRankedSelect}
                                disabled={isAnswerDisabled}
                              />

                              {isLastSelectedAnswerOpen && (
                                <div className="option-text">
                                  <label>
                                    For multiple answer seperate the words by
                                    comma ,
                                  </label>

                                  <TextField
                                    id="outlined-basic"
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    name="optionText"
                                    value={optionText}
                                    onChange={(e) =>
                                      setOptionText(e.target.value)
                                    }
                                    variant="outlined"
                                  />
                                </div>
                              )}
                            </Grid>
                          )}

                          {isInterview && (
                            <Grid item xs={12} width={"100%"}>
                              <VideoChat
                                id={params.id}
                                panelist_={`${userData.firstName}${userData.lastInitial}`}
                                firstName_={userData.firstName}
                                interviewTitle={questions[index].title}
                                onFinish={finishInterview}
                                onInterviewIndex={setInterviewIndex}
                                isLastQuestion={isLast}
                                questionCount={questions[index].questionTotal}
                                submitInProgress={isSubmitInProgress}
                              />
                            </Grid>
                          )}

                          {isVideo && (
                            <VideoCapture
                              disabled={isAnswerDisabled}
                              onChange={handleMediaChange}
                              data={questions[index].question}
                              isPromptMediaPlaying={isPromptMediaPlaying}
                              onRecording={setMediaRecord}
                              onPlaying={setCaptureMediaChanges}
                              nextEnabled={isNextEnabled}
                              isSingleSection={true}
                              isLast={isLast}
                              onHandlingData={handleMediaData}
                            />
                          )}

                          {isAudio && (
                            <AudioCapture
                              disabled={isAnswerDisabled}
                              onChange={handleMediaChange}
                              data={questions[index].question}
                              isPromptMediaPlaying={isPromptMediaPlaying}
                              onRecording={setMediaRecord}
                              onPlaying={setCaptureMediaChanges}
                              userData={userData}
                              isSingleSection={true}
                              nextEnabled={isNextEnabled}
                              isLast={isLast}
                              onHandlingData={handleMediaData}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {isProgressBar && progress > 1 && (
                      <Grid item xs={12} className="progress-bar">
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                        />
                      </Grid>
                    )}

                    {interviewInProgress && (
                      <Grid item xs={12} className="progress-bar">
                        <LinearProgress />
                      </Grid>
                    )}
                  </div>
                ) : null}
              </CardContent>
              {isPortrait && (
                <CardActions className={classes.cardActionCustom}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    {isPrev ? (
                      <Button
                        variant="contained"
                        onClick={prevQuestion}
                        disabled={isProgressBar}
                      >
                        <Typography sx={AnswerText}>Prev</Typography>
                      </Button>
                    ) : (
                      <div></div>
                    )}

                    {!isLast ? (
                      <Grid item>
                        {isSkippable && isNextEnabled ? (
                          <Button
                            variant="contained"
                            endIcon={<KeyboardDoubleArrowRightOutlinedIcon />}
                            onClick={handleSkip}
                          >
                            <Typography sx={AnswerText}>Skip</Typography>
                          </Button>
                        ) : (
                          <Grid item>
                            {isVideo || isAudio || isText ? (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  if (isVideo) handleDownload();
                                  else if (isAudio) onAudioSubmit();
                                  else onTextSubmit();
                                }}
                                disabled={
                                  mediaFileData === null &&
                                  textValue.length <= 3 &&
                                  !isProgressBar
                                }
                              >
                                <Typography sx={AnswerText}>Next</Typography>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={nextQuestion}
                                disabled={isNextEnabled}
                              >
                                <Typography sx={AnswerText}>Next</Typography>
                              </Button>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <div>
                        {isVideo || isAudio || isText ? (
                          <Button
                            variant="contained"
                            onClick={() => {
                              if (isVideo) handleDownload();
                              else if (isAudio) onAudioSubmit();
                              else onTextSubmit();
                            }}
                            disabled={
                              mediaFileData === null &&
                              textValue.length <= 3 &&
                              !isProgressBar
                            }
                          >
                            <Typography sx={AnswerText}>Submit</Typography>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={onSubmit}
                            disabled={isNextEnabled}
                          >
                            <Typography sx={AnswerText}>Submit</Typography>
                          </Button>
                        )}
                      </div>
                    )}
                  </Grid>
                </CardActions>
              )}
            </Card>
          </Grid>
          {!isPortrait && (
            <Grid item xs={2}>
              {!isLast ? (
                <Grid item>
                  {isSkippable && isNextEnabled ? (
                    <Button
                      className={classes.centeredButton}
                      variant="contained"
                      endIcon={
                        <KeyboardDoubleArrowRightOutlinedIcon
                          style={{ fontSize: 20 }}
                        />
                      }
                      onClick={handleSkip}
                    >
                      <Typography sx={AnswerText}>Skip</Typography>{" "}
                    </Button>
                  ) : (
                    <Grid item>
                      {isVideo || isAudio || isText ? (
                        <Button
                          className={classes.centeredButton}
                          variant="contained"
                          onClick={() => {
                            if (isVideo) handleDownload();
                            else if (isAudio) onAudioSubmit();
                            else onTextSubmit();
                          }}
                          disabled={
                            mediaFileData === null &&
                            textValue.length <= 3 &&
                            !isProgressBar
                          }
                        >
                          <Typography sx={AnswerText}>Next</Typography>
                        </Button>
                      ) : (
                        <Grid
                          item
                          container
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Button
                            className={classes.centeredButton}
                            variant="contained"
                            onClick={nextQuestion}
                            disabled={isNextEnabled}
                          >
                            <Typography sx={AnswerText}>Next</Typography>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              ) : (
                <div>
                  {isVideo || isAudio || isText ? (
                    <Button
                      className={classes.centeredButton}
                      variant="contained"
                      onClick={() => {
                        if (isVideo) handleDownload();
                        else if (isAudio) onAudioSubmit();
                        else onTextSubmit();
                      }}
                      disabled={
                        mediaFileData === null &&
                        textValue.length <= 3 &&
                        !isProgressBar
                      }
                    >
                      <Typography sx={AnswerText}>Submit</Typography>
                    </Button>
                  ) : (
                    <Button
                      className={classes.centeredButton}
                      variant="contained"
                      onClick={onSubmit}
                      disabled={
                        value !== "" ? false : isCheckbox ? false : true
                      }
                    >
                      <Typography sx={AnswerText}>Submit</Typography>
                    </Button>
                  )}
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
};

export default QuestionV3;
