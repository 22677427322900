import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import ReactPlayer from "react-player/lazy";
import "../App.css";
import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import { Keys } from "../common/keys";

const PromptQuestion = (props) => {
  const [isText, setIsText] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [mediaUrl, setMediaUrl] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const [isControlsEnabled, setIsControlsEnabled] = useState(true);
  const getPlayedQuestions = getLocalStorage(Keys.MediaPalyedOnce);
  const questionStr =
    props.pType === "video" ? props.quest[props.videoId || 0] : props.quest;

  useEffect(() => {
    if (props.isMediaAnswerPlaying || props.isMediaRecording)
      setIsPlaying(false);
  }, [props.isMediaAnswerPlaying, props.isMediaRecording]);

  useEffect(() => {
    setIsPlaying(!props.isAnswered);
    if (props.isAnswered) props.onPlayedOnce(true);
  }, [props.isAnswered]);

  useEffect(() => {
    if (props.isMediaRecording) {
      setIsControlsEnabled(false);
      setIsPlaying(false);
    } else setIsControlsEnabled(true);
  }, [props.isMediaRecording]);

  useEffect(() => {
    async function fetchMedia() {
      let result = "";
      try {
        result = await Storage.get(questionStr, {
          expires: 86400,
        });
      } catch (error) {
        console.error(error);
      }
      setIsText(false);
      setIsAudio(false);
      setIsVideo(false);

      switch (props.pType) {
        case "video":
          setIsVideo(true);
          setMediaUrl(result);
          break;
        case "audio":
          setIsAudio(true);
          setMediaUrl(result);
          break;
        case "text":
          props.onPlayedOnce(true);
          setIsText(true);
          break;
        default:
          props.onPlayedOnce(true);
          setIsText(true);
          break;
      }
    }

    props.onPlayedOnce(false);

    if (props.isAnswered) {
      props.onMediaChange(!props.isAnswered);
      props.onPlayedOnce(props.isAnswered);
    }

    if (!getPlayedQuestions.includes(questionStr)) setIsPlaying(true);
    else props.onPlayedOnce(true);

    fetchMedia();
  }, [props.pType, props.quest]);

  const onMediaPlayerPlaying = () => {
    setIsPlaying(true);
    props.onMediaChange(true);
  };

  const onMediaPlayerPause = () => {
    setIsPlaying(false);
    props.onMediaChange(false);
  };

  const onMediaPlayerStopped = () => {
    let newArr = [...getPlayedQuestions];
    if (!newArr.includes(questionStr)) newArr.push(questionStr);
    setLocalStorage(Keys.MediaPalyedOnce, newArr);
    setIsPlaying(false);
    props.onMediaChange(false);
    props.onPlayedOnce(true);
  };

  return (
    <div className="center">
      {isText && (
        <div
          className="text-start"
          dangerouslySetInnerHTML={{ __html: props.quest }}
        ></div>
      )}

      {isAudio && (
        <ReactPlayer
          playsinline={true}
          className="audio-prompt-container"
          onPlay={onMediaPlayerPlaying}
          onPause={onMediaPlayerPause}
          onEnded={onMediaPlayerStopped}
          playing={isPlaying}
          width="300px"
          height="50px"
          url={mediaUrl}
          controls={isControlsEnabled}
        />
      )}

      {isVideo && (
        <ReactPlayer
          controls={isControlsEnabled}
          className={"prompt-video"}
          playsinline={true}
          onPlay={onMediaPlayerPlaying}
          onPause={onMediaPlayerPause}
          onEnded={onMediaPlayerStopped}
          onSeek={() => {
            setIsPlaying(true);
          }}
          playing={isPlaying}
          width={isPlaying ? "100%" : "60%"}
          height={isPlaying ? "100%" : "60%"}
          url={mediaUrl}
        />
      )}
    </div>
  );
};

export default PromptQuestion;
