import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";
import { getSurvey } from "../graphql/queries";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  FormControl,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Result } from "antd";

import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";

import "antd/dist/antd.css";
import CustomMultiSelect from "../components/CustomMultiSelect";
import CustomSelect from "../components/CustomSelect";
import CustomRating from "../components/CustomRating";
import Study from "./study";

import { params } from "../common/util";
import { Screen_Out_Message } from "../common/constant";

// css
import Styles from "../assets/css/styles";
import {
  QuestionText,
  AnswerText,
  CardContainer,
  prescreenLabel,
  MsgLabelJustify,
} from "../assets/css/sxStyles";
import "../App.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { Keys } from "../common/keys";

const cardStyle = {
  padding: "0 1rem 1em 1rem",
};

const Screening = ({ onSignUp }) => {
  const classes = Styles();

  const [screenOutQuestions, setScreenOutQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  );
  window.onresize = () => {
    if (window.innerWidth < window.innerHeight && !isPortrait)
      setIsPortrait(!isPortrait);
  };
  const [answersList, setAnswersList] = useState([]);

  // answer Type
  const [isRadio, setIsRadio] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isRankedSelect, setIsRankedSelect] = useState(false);

  // Answer option
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState("");

  // set prev button
  const [isPrev, setIsPrev] = useState(false);

  // For open answer
  const [isLastSelectedAnswerOpen, setIsLastSelectedAnswerOpen] =
    useState(false);

  const [isNextEnabled, setIsNextEnabled] = useState(true);
  const [optionText, setOptionText] = useState("");
  const [isTesting, setIsTesting] = useState(false);

  const [isScreenedOut, setIsScreenedOut] = useState(false);
  const [startStudy, setStartStudy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [screenOutMessage, setScreenOutMessage] = useState(Screen_Out_Message);
  const [isNoStudy, setIsNoStudy] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      const response = await API.graphql({
        query: getSurvey,
        variables: { id: params.id },
        authMode: "AWS_IAM",
      });

      setIsTesting(params.test);

      if (response.data.getSurvey.isScreening) {
        const screenOutString = JSON.parse(
          response.data.getSurvey.screenOutData
        );

        const message = response.data.getSurvey.optionalScreenedOutMessage;
        if (message != null && message.trim() !== "")
          setScreenOutMessage(message);

        setQuestions(...[], screenOutString);
        setScreenOutQuestions(
          ...[],
          screenOutString.map((element) => ({
            ...element,
            answers: element.answers.map((item) => item.answer),
          }))
        );

        if (params.test || response.data.getSurvey.surveyStatus === "TESTING")
          setLocalStorage(Keys.ScreenoutData, []);
        else if (
          response.data.getSurvey.isJournaling &&
          `${params.id}-user` in localStorage
        )
          setStartStudy(true);

        switch (response.data.getSurvey.surveyStatus) {
          case "TESTING":
            setIsTesting(true);
            break;
          case "CLOSE":
            setStartStudy(true);
            break;
          default:
            break;
        }
        setIsLoading(false);
      } else setStartStudy(true);
    }
    fetchData().catch((error) => {
      setIsNoStudy(true);
      console.error("Error:", error.errors[0].message);
    });
  }, [setIsTesting]);

  useEffect(() => {
    const screeningData = getLocalStorage(Keys.ScreenoutData);

    if (screeningData.length > index && screenOutQuestions.length > 0) {
      setOptionalText(index);
      setValue(screeningData[index].answer);
    }
  }, [index, screenOutQuestions]);

  useEffect(() => {
    let screeningData = getLocalStorage(Keys.ScreenoutData);
    const screenOutStr = getLocalStorage(Keys.Screened);

    const isScreenOut = screenOutStr?.length === 0 ? false : screenOutStr;

    let dataindex = 0;
    if (screeningData.length > 0) {
      dataindex = screeningData.length;
      setAnswersList(...[], screeningData);
      if (screeningData.length === screenOutQuestions.length && !isScreenOut)
        setStartStudy(true);
    }

    setIsScreenedOut(isScreenOut && !isTesting);
    setIndex(dataindex);
    setPrevButton(dataindex);

    setAnswerOption(screenOutQuestions[dataindex]?.answerType);
  }, [screenOutQuestions, setIndex]);

  useEffect(() => {
    if ((isRadio || isCheckbox) && isLastSelectedAnswerOpen)
      if (optionText.length > 1) setIsNextEnabled(false);
      else setIsNextEnabled(true);
    else if ((isRadio || isCheckbox) && value.length > 1)
      setIsNextEnabled(false);
    else if (value != "") setIsNextEnabled(false);
    else setIsNextEnabled(true);
  }, [
    isLastSelectedAnswerOpen,
    isCheckbox,
    isRadio,
    optionText,
    value,
    setIsNextEnabled,
  ]);

  const handleChange = (event) => {
    let lastAnsIndex = 0;

    const curQuestion = screenOutQuestions.find(
      (x) => x.question.toString() === event.target.name.toString()
    );

    const curIndex = screenOutQuestions.findIndex((object) => {
      return object.title === curQuestion.title;
    });

    if (isCheckbox) {
      let checkedArray = event.target.value.split(";");
      lastAnsIndex = screenOutQuestions[curIndex].answers.length - 1;
      if (screenOutQuestions[curIndex].answersOpen) {
        if (
          checkedArray.includes(
            screenOutQuestions[curIndex].answers[lastAnsIndex]
          )
        )
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
      }
      setValue(checkedArray.join(";"));
    } else if (isRadio) {
      setValue(event.target.value);
      lastAnsIndex = screenOutQuestions[curIndex].answers.length - 1;
      if (screenOutQuestions[curIndex].answersOpen)
        if (
          screenOutQuestions[curIndex].answers[lastAnsIndex] ===
          event.target.value
        )
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
    } else setValue(event.target.value);
  };

  const setOptionalText = (i) => {
    const screeningData = getLocalStorage(Keys.ScreenoutData);
    if (screeningData && screeningData.length > i) {
      let answerVal = screeningData.find(
        (x) => x.question === screenOutQuestions[i].title
      ).answer;

      setValue(answerVal);
      if (screenOutQuestions[i].answersOpen) {
        const answers = screenOutQuestions[i].answers;
        if (screenOutQuestions[i].answerType === "single-Select") {
          if (!answers.includes(answerVal)) {
            setIsLastSelectedAnswerOpen(true);
            setOptionText(answerVal);
            setValue(answers[answers.length - 1]);
          }
        } else if (screenOutQuestions[i].answerType === "multi-Select") {
          const answerOptionsList = screenOutQuestions[i].answers;
          let answersData = [];

          answersData = answerVal.split(";");
          let uncheckedData = [];
          let checkedData = [];
          answersData.forEach((element) => {
            if (!answerOptionsList.includes(element))
              uncheckedData.push(element);
            else checkedData.push(element);
          });

          if (uncheckedData.length > 0 && uncheckedData[0] !== "") {
            checkedData.push(answerOptionsList[answerOptionsList.length - 1]);
            setValue(checkedData.join(";"));
            setIsLastSelectedAnswerOpen(true);
            setOptionText(uncheckedData.join(","));
          }
        }
      }
    }
  };

  const nextQuestion = async () => {
    let answerData = [];
    let valueString = value;
    if (isLastSelectedAnswerOpen) {
      if (isCheckbox) {
        const lastAnswerOptionsString =
          screenOutQuestions[index].answers[
            screenOutQuestions[index].answers.length - 1
          ];
        const islastSelected = value
          .split(";")
          .includes(lastAnswerOptionsString);
        if (islastSelected) {
          if (optionText !== "") {
            const stringText = optionText.split(",");
            let vlarr = value.split(";").concat(stringText);
            vlarr = vlarr.filter((e) => e !== lastAnswerOptionsString);
            valueString = [...new Set(vlarr)].join(";");
          } else valueString = "";
        }
      } else valueString = optionText;
    }

    if (answersList && answersList.length !== 0) {
      answerData = answersList;
      if (answersList.length > index) {
        answersList.forEach((obj) => {
          if (obj.question === questions[index].title) obj.answer = valueString;

          let ansD = getLocalStorage(Keys.ScreenoutData);
          const answerListVal = ansD.find(function (element) {
            return element.question === questions[index].title;
          });

          let val = value;
          if (questions[index].answersOpen) {
            const ansList = screenOutQuestions[index].answers;
            if (questions[index].answerType === "single-Select") {
              if (ansList[ansList.length - 1] === value) val = optionText;
            } else {
              const valList = value.split(";");
              if (valList.includes(ansList[ansList.length - 1])) {
                valList.filter(function (ele) {
                  return ele !== ansList[ansList.length - 1];
                });
                valList.push(optionText);
                val = [...new Set(valList)].join(";");
              }
            }
          }

          if (answerListVal.answer !== val) {
            var found = answerData.findIndex(function (element) {
              return element.question === questions[index].title;
            });
            answersList.splice(found + 1);
          }
        });

        answerData = answersList;
      } else
        answerData.push({
          question: screenOutQuestions[index].title,
          answer: valueString,
          index: "ps" + index,
          type: screenOutQuestions[index].answerType,
        });
    } else
      answerData.push({
        question: screenOutQuestions[index].title,
        answer: valueString,
        index: "ps" + index,
        type: screenOutQuestions[index].answerType,
      });

    setLocalStorage(Keys.ScreenoutData, answerData);
    setAnswersList(...[], answerData);

    let valueArr = value.split(";");

    if (isRating) valueArr = value.split("-").slice(1, 2);

    const matchedObjects = questions[index].answers
      .filter((obj) => value.includes(obj.answer))
      .map((obj) => obj.isScreenOut);
    const hasTrue = matchedObjects.some((element) => element === true);

    setIsScreenedOut(hasTrue);
    setLocalStorage(Keys.Screened, hasTrue);

    setIsLastSelectedAnswerOpen(false);
    setValue("");
    if (index + 1 < screenOutQuestions.length) {
      setIndex(index + 1);
      setPrevButton(index + 1);
      setAnswerOption(screenOutQuestions[index + 1].answerType);
    } else if (!hasTrue) setStartStudy(true);
  };

  const prevQuestion = () => {
    setIndex(index - 1);
    setPrevButton(index - 1);
    setAnswerOption(questions[index - 1].answerType);
    let ansD = getLocalStorage(Keys.ScreenoutData);

    setValue(ansD[index - 1].answer);
    setIsLastSelectedAnswerOpen(false);
    setOptionText("");
    setAnswerOption(questions[index - 1].answerType);

    setOptionalText(parseInt(ansD[index - 1].index.replace("ps", "")));
  };

  const setPrevButton = (value) => {
    if (value > 0) setIsPrev(true);
    else setIsPrev(false);
  };

  const setAnswerOption = (val) => {
    setIsCheckbox(false);
    setIsRadio(false);
    setIsRating(false);
    switch (val) {
      case "single-Select":
        setIsRadio(true);
        break;
      case "multi-Select":
        setIsCheckbox(true);
        setIsRankedSelect(false);
        break;
      case "rating":
        setIsRating(true);
        break;
      default:
        break;
    }
  };

  if (isNoStudy) return <Authenticator initialState="signUp" />;

  if (startStudy) return <Study onSignUp={onSignUp} />;

  if (isLoading)
    return (
      <CircularProgress
        style={{
          width: "200px",
          height: "200px",
          color: "lightgrey",
        }}
      />
    );

  if (isScreenedOut) {
    return (
      <div className="card" style={cardStyle}>
        <Result
          status="warning"
          title={
            <Typography sx={QuestionText} align="center">
              Thank You for Your Time!
            </Typography>
          }
          subTitle={
            <Typography sx={MsgLabelJustify}>
              <br /> {screenOutMessage} <br />
            </Typography>
          }
        />
      </div>
    );
  } else {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {!isPortrait && (
          <Grid item xs={2} alignItems="center">
            {isPrev ? (
              <Button
                className={classes.centeredButton}
                variant="contained"
                onClick={prevQuestion}
              >
                <Typography sx={AnswerText}>Prev</Typography>
              </Button>
            ) : (
              <div></div>
            )}
          </Grid>
        )}
        <Grid item xs="auto">
          <Card sx={CardContainer}>
            <Grid container>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Typography sx={prescreenLabel}>{`Pre-Screening Question ${
                  index + 1
                }/${screenOutQuestions.length}`}</Typography>
              </Grid>
            </Grid>

            <CardContent style={cardStyle}>
              {screenOutQuestions.length > 0 ? (
                <div className={classes.root}>
                  <Grid container>
                    <Grid item xs={12} padding={0}>
                      <FormControl
                        component="fieldset"
                        style={{ gap: "0.75em" }}
                        fullWidth
                      >
                        <Grid item xs={12} className="prompt-video-container">
                          {screenOutQuestions[index].promptType === "text" && (
                            <Grid item xs={12}>
                              <div
                                className="text-start"
                                dangerouslySetInnerHTML={{
                                  __html: screenOutQuestions[index].question,
                                }}
                              ></div>
                            </Grid>
                          )}
                        </Grid>

                        {isRadio && (
                          <Grid
                            item
                            xs={12}
                            width="100%"
                            style={{ gap: "2em" }}
                          >
                            <CustomSelect
                              onChange={handleChange}
                              data={screenOutQuestions[index]}
                              value={value}
                            />
                            {isLastSelectedAnswerOpen && (
                              <div className="option-text">
                                <TextField
                                  id="outlined-basic"
                                  label=" "
                                  InputLabelProps={{ shrink: false }}
                                  size="small"
                                  name="optionText"
                                  value={optionText}
                                  placeholder="Please enter your option"
                                  onChange={(e) =>
                                    setOptionText(e.target.value)
                                  }
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </Grid>
                        )}

                        {isRating && (
                          <Grid container>
                            <CustomRating
                              onChange={handleChange}
                              data={screenOutQuestions[index]}
                              value={value}
                            />
                          </Grid>
                        )}

                        {isCheckbox && (
                          <Grid item xs={12} width={"100%"}>
                            <CustomMultiSelect
                              onChange={handleChange}
                              data={screenOutQuestions[index]}
                              value={value}
                              isRankedSelect={isRankedSelect}
                            />

                            {isLastSelectedAnswerOpen && (
                              <div className="option-text">
                                <label>
                                  For multiple answer seperate the words by
                                  comma ,
                                </label>

                                <TextField
                                  id="outlined-basic"
                                  label=" "
                                  InputLabelProps={{ shrink: false }}
                                  size="small"
                                  name="optionText"
                                  value={optionText}
                                  onChange={(e) =>
                                    setOptionText(e.target.value)
                                  }
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </Grid>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
            </CardContent>
            {isPortrait && (
              <CardActions className={classes.cardActionCustom}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {isPrev ? (
                    <Button variant="contained" onClick={prevQuestion}>
                      <Typography sx={AnswerText}>Prev</Typography>
                    </Button>
                  ) : (
                    <div></div>
                  )}

                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={nextQuestion}
                      disabled={isNextEnabled}
                    >
                      <Typography sx={AnswerText}>Next</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            )}
          </Card>
        </Grid>
        {!isPortrait && (
          <Grid item xs={2}>
            <Grid
              item
              container
              display={"flex"}
              flexDirection={"column"}
              gap={2}
            >
              <Button
                className={classes.centeredButton}
                variant="contained"
                onClick={nextQuestion}
                disabled={isNextEnabled}
              >
                <Typography sx={AnswerText}>Next</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
};

export default Screening;
