export const StudyHeaderText = {
  textAlign: "Left",
  fontSize: {
    xs: 15,
    sm: 18,
    md: 21,
    lg: 25,
    xl: 28,
  },
  fontWeight: 500,
  mb: 1.5,
};

export const HeaderText = {
  fontSize: {
    xs: 20,
    sm: 20,
    md: 25,
    lg: 30,
    xl: 30,
  },
};

export const HeaderText2 = {
  m: 3,
  fontSize: {
    xs: 20,
    sm: 20,
    md: 25,
    lg: 30,
    xl: 30,
  },
};

export const SectionHeaderText = {
  m: 1,
  // color:"#1664C0",
  fontSize: {
    xs: 20,
    sm: 20,
    md: 23,
    lg: 27,
    xl: 27,
  },
  fontWeight: "900",
};

export const QuestionTittleText = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 18,
    lg: 21,
    xl: 21,
  },
  fontWeight: "600",
  width: "65%",
  flexShrink: 0,
};

export const prescreenLabel = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 18,
    lg: 21,
    xl: 21,
  },
  fontWeight: "500",
  m: 3,
};

export const infoText = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 18,
    lg: 21,
    xl: 21,
  },
  fontWeight: "500",
  m: 1,
  textAlign: "justify",
};

export const QuestionLabels = {
  width: "35%",
  height: "100%",
  fontWeight: "400",
  color: "#BCBCBC",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingRight: "8px",
};

export const QuestionText = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 25,
  },
};

export const AnswerText = {
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
};

export const StudyText = {
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
  textAlign: "justify",
};

export const MsgLabelJustify = {
  textAlign: "justify",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 15,
    lg: 20,
    xl: 20,
  },
};

export const Labels = {
  fontSize: {
    xs: 10,
    sm: 10,
    md: 12,
    lg: 13,
    xl: 13,
  },
};

export const studyStartButton = {
  m: 0,
  p: 0,
  mt: 2,
  display: "flex",
  justifyContent: "center",
};

export const NoteText = {
  alignSelf: "flex-start",
  color: "#13A3B8",
  margin: "0px",
  height: "100%",
  fontWeight: "bold",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 14,
    xl: 16,
  },
};

export const CardContainer = {
  minWidth: 275,
  width: "90vw",
  maxWidth: 500,
  overflowWrap: "anywhere",
  margin: "16px",
  maxHeight: "90vh",
  overflowY: "auto",
};

export const SectionsContainer = {
  minWidth: 275,
  width: "100%",
  maxWidth: {
    xs: "500px !important",
    sm: "500px !important",
    md: "500px !important",
    lg: "500px !important",
    xl: "700px !important",
  },
  overflowWrap: "anywhere",
  margin: "16px",
  alignSelf: "flex-start",
};

export const GridContainer = {
  overflowWrap: "anywhere",
  textAlign: "center",
  padding: "2em",
};

export const modalContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "80%",
    md: "45%",
    lg: "45%",
    xl: "45%",
  },
  boxShadow: 0,
  p: 0,
};

export const marginTop = {
  marginTop: "1.5em",
};

export const marginLeft = {
  marginLeft: "1.5em",
};

export const marginVertical = {
  marginTop: "1.5em",
  marginBottom: "1.5em",
};

export const column = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "1em",
};
